<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>
    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Noticias',
              quantity: data.counters.notices.total,
              icon: 'trophy',
              change: data.counters.notices.actives,
              changeVar: 'noticias activos'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
        
        </div>

        <div style="height: 130px" class="mx-1">
          
        </div>
        <div style="height: 130px" class="mx-1">
        
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
           <t-input-group class="mx-2">
            <t-select
              v-model="parameters.active"
              placeholder="Estatus"
              :options="[{ id: 1, name: 'Activas' },{ id: 0, name: 'Inactivas' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_notice_category"
              placeholder="Selecciona una categoría"
              :options="notice_categories"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          class="my-auto mr-2 h-fit py-2"
          @click="$router.push('/noticias/crear')"
        >
          Crear noticia
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="notice"
          :headers="headers"
          :url="'noticias'"
          :data="notices"
          :route="'/notice'"
          :tableName="'NOTICIAS'"
          :parameters="parameters"
          :canView="'/noticia/detalle/'"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "Imagen",
        "Título",
        "Descripción",
        "Categoría",
        "Likes",
        "Publicada",
        "Acciones"
      ],
      data: {
        counters: {
          notices: {},
          academies: {},
          players: {},
          coaches: {},
        
          games: {},
          categories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {
        id_notice_category: null,
        active:1
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
   
     notice_categories() {
      return this.$store.getters["global/getItems"](
        "noticeCategory",
        "unpaginated_elements"
      )
    },
    notices() {
      return this.$store.getters["global/getItems"](
        "notice",
        "elements"
      ).map((el) => ({
        id:el.id,
         image: el.url_picture,
        name: el.title,
        body_short: el.body_short.length > 100 ? el.body_short.substring(0,100) + '...' : el.body_short,
         notice_category: el.notice_category.name,
        
        total_likes: el.total_likes,
        slug:el.token,
        datetime: el.created_at,
        active:el.active
      }))
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getNoticesCategories() {
      this.$store
        .dispatch("global/getItems", {
          module: "noticeCategory",
          route: "/notice_category/all",
          noPaginate: true,
          isMaster: true,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getCounters()
    this.getNoticesCategories()
  }
}
</script>
